import React from 'react';
import logo from './logoGG.png';
import background from './background.jpeg'; // Import the background image

function App() {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
     <img
        src={logo}
        alt="Garlick Gardens Logo"
        style={{
          maxWidth: '100%',
          maxHeight: '70%', // Adjusts size if needed
          marginTop: '-300px', // Move it up by 100 pixels
        }}
      /></div>
  );
}

export default App;
